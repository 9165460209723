import { Currency, Pair, Token } from 'sdk'
import { Button, ChevronDownIcon, Text, useModal, Flex, Box, MetamaskIcon } from 'uikit'
import styled from 'styled-components'
import { registerToken } from 'utils/wallet'
import { isAddress } from 'utils'
import { useTranslation } from 'contexts/Localization'
import useActiveWeb3React from 'hooks/useActiveWeb3React'
import { useCurrencyBalance } from '../../state/wallet/hooks'
import CurrencySearchModal from '../SearchModal/CurrencySearchModal'
import { CurrencyLogo, DoubleCurrencyLogo } from '../Logo'

import { Input as NumericalInput } from './NumericalInput'
import { CopyButton } from '../CopyButton'
import Row from '../Layout/Row'
import Column from "../Layout/Column";
import {getTokenSlippage} from "../../config";

const CurrencySelectButton = styled(Button).attrs({ variant: 'text', scale: 'sm' })`
  padding: 0 0.5rem;
`
const LabelRow = styled.div`
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  color: ${({ theme }) => theme.colors.text};
  font-size: 0.75rem;
  line-height: 1rem;
  flex: 1;
  height: 100%;
`
const InputPanel = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  border-radius: 15px;
  background-color: ${({ theme }) => theme.colors.background};
  z-index: 1;
  height: 100px;
  padding: 16px;
  font-size: 20px;
`
const Label = styled.div`
  position: absolute;
  top: 16px;
  left: 16px;
  font-size: 14px;
  color: ${({ theme }) => theme.colors.textDisabled};

  &.right {
    left: auto;
    right: 16px;
  }
`
interface CurrencyInputPanelProps {
  value: string
  onUserInput: (value: string) => void
  onMax?: () => void
  showMaxButton: boolean
  label?: string
  onCurrencySelect: (currency: Currency) => void
  currency?: Currency | null
  disableCurrencySelect?: boolean
  hideBalance?: boolean
  pair?: Pair | null
  otherCurrency?: Currency | null
  id: string
  showCommonBases?: boolean
}
export default function CurrencyInputPanel({
  value,
  onUserInput,
  onMax,
  showMaxButton,
  label,
  onCurrencySelect,
  currency,
  disableCurrencySelect = false,
  hideBalance = false,
  pair = null, // used for double token logo
  otherCurrency,
  id,
  showCommonBases,
}: CurrencyInputPanelProps) {
  const { account, library } = useActiveWeb3React()
  const selectedCurrencyBalance = useCurrencyBalance(account ?? undefined, currency ?? undefined)
  const {
    t,
    currentLanguage: { locale },
  } = useTranslation()

  const token = pair ? pair.liquidityToken : currency instanceof Token ? currency : null
  const tokenAddress = token ? isAddress(token.address) : null

  const [onPresentCurrencyModal] = useModal(
    <CurrencySearchModal
      onCurrencySelect={onCurrencySelect}
      selectedCurrency={currency}
      otherSelectedCurrency={otherCurrency}
      showCommonBases={showCommonBases}
    />,
  )
  return (
    <Box position="relative" id={id}>
      {/* 余额 */}
      {/* <Flex mb="6px" alignItems="center" justifyContent="space-between">
        {account && (
          <Text onClick={onMax} color="textSubtle" fontSize="14px" style={{ display: 'inline', cursor: 'pointer' }}>
            {!hideBalance && !!currency
              ? t('Balance: %balance%', { balance: selectedCurrencyBalance?.toSignificant(6) ?? t('Loading') })
              : ' -'}
          </Text>
        )}
      </Flex> */}
      <Flex  mb="6px" alignItems="center" justifyContent="flex-end">
        {account && (
            <Text color="textSubtle" fontSize="14px" style={{ display: 'inline', cursor: 'pointer' }}>
              {!!currency && getTokenSlippage(tokenAddress)>0
                  ? t('Token Slippage: %rate%', { rate: getTokenSlippage(tokenAddress)+'%' })
                  : ''}
            </Text>
        )}
      </Flex>
      <InputPanel>
        <Label>{label}</Label>
        <Label className="right">
          {!hideBalance && account && (
            <Column>
              <Text onClick={onMax} color="textDisabled" fontSize="14px" style={{ display: 'inline', cursor: 'pointer' }}>
                {!hideBalance && !!currency
                    ? t('Balance: %balance%', { balance: selectedCurrencyBalance?.toSignificant(6) ?? t('Loading') })
                    : ' -'}
              </Text>
            </Column>
          )}
        </Label>
        <LabelRow>
          <NumericalInput
            align="left"
            className="token-amount-input"
            value={value}
            onUserInput={(val) => {
              onUserInput(val)
            }}
          />
        </LabelRow>
        {account && currency && showMaxButton && label !== 'To' && (
          <Button onClick={onMax} scale="xs" variant="text">
            {t('Max').toLocaleUpperCase(locale)}
          </Button>
        )}
        <CurrencySelectButton
          className="open-currency-select-button"
          selected={!!currency}
          onClick={() => {
            if (!disableCurrencySelect) {
              onPresentCurrencyModal()
            }
          }}
        >
          <Flex alignItems="center" justifyContent="space-between">
            {pair ? (
              <DoubleCurrencyLogo currency0={pair.token0} currency1={pair.token1} size={16} margin />
            ) : currency ? (
              <CurrencyLogo currency={currency} size="24px" style={{ marginRight: '8px' }} />
            ) : null}
            {pair ? (
              <Text id="pair" bold>
                {pair?.token0.symbol}:{pair?.token1.symbol}
              </Text>
            ) : (
              <Text id="pair" bold>
                {(currency && currency.symbol && currency.symbol.length > 20
                  ? `${currency.symbol.slice(0, 4)}...${currency.symbol.slice(
                      currency.symbol.length - 5,
                      currency.symbol.length,
                    )}`
                  : currency?.symbol) || t('Select a currency')}
              </Text>
            )}
            {!disableCurrencySelect && <ChevronDownIcon />}
          </Flex>
        </CurrencySelectButton>
      </InputPanel>
    </Box>
  )
}
